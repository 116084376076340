// @mui
import { List, Stack } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => (
        <List disablePadding sx={{ px: 2 }}>
          {group.subheader && (
            <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
          )}

          {group.items.map((list, index) => (
            <NavList
              key={list.path + index}
              data={list}
              depth={1}
              hasChild={!!list.children}
            />
          ))}
        </List>
      ))}
    </Stack>
  );
}
