import { useMemo, useState, memo, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  Box,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';
import { IconButtonAnimate } from '../../../components/animate';
// routes
import { PATH_DICTATION, PATH_FAVORITES } from '../../../routes/paths';
// utils
import { tracking, TrackingType } from '../../../utils/trackingEvent';

function NavBackIcon() {
  const navigate = useNavigate();
  const { elementId, channelId } = useParams();
  const { pathname } = useLocation();

  const backPath = useMemo(() => ({
    [PATH_DICTATION.elements(Number(channelId))]: PATH_DICTATION.channels,
    [PATH_DICTATION.details(Number(channelId), Number(elementId))]: PATH_DICTATION.elements(Number(channelId)),
    [PATH_FAVORITES.details(Number(elementId))]: PATH_FAVORITES.elements,
  }[pathname] || ''), [pathname, elementId, channelId])

  return (
    <Box>
      <IconButtonAnimate onClick={() => {
        if(backPath) {
          navigate(backPath, { replace: true });
        } else {
          navigate(-1);
        }
      }}>
        <Iconify icon="ion:arrow-back" />
      </IconButtonAnimate>
    </Box>
  );
}

export default memo(NavBackIcon);