import { Translation } from 'react-i18next';
// routes
import { PATH_DICTATION, PATH_FAVORITES } from '../../../routes/paths';
// components
// import Label from '../../../components/label';
// import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [

  // Dictation
  // ----------------------------------------------------------------------
  {
    subheader: 'GENERAL',
    items: [
      {
        title: <Translation>{(translate) => translate('nav.dictation')}</Translation>,
        path: PATH_DICTATION.root,
        icon: ICONS.mail,
      },
      {
        title: <Translation>{(translate) => translate('nav.favorites')}</Translation>,
        path: PATH_FAVORITES.root,
        icon: ICONS.label,
      },
    ],
  },
];

export default navConfig;
