/**
 * example: https://www.learmonade.com/dictation => https://www.learmonade.cn/dictation
*/
export function getAvailableAddress(address: string) : string {
    const availableSuffix = window.location.href.match(/.com|.cn/);
    const availableAddress = availableSuffix ? address.replace(/.com|.cn/, availableSuffix[0]) : address;
    return availableAddress;
  }

export function isCNAddress() : boolean {
    const availableSuffix = window.location.href.match(/.com|.cn/);
    const isCNAddr = availableSuffix && availableSuffix[0] === '.cn' || localStorage.getItem('ipLocation')?.toLocaleUpperCase() === 'CN';
    return !!isCNAddr;
}

export function isAdminPage() : boolean {
  const { origin } = window.location;
  const isMatchAdmin = window.location.href.match(`${origin}/admin`);
  return !!isMatchAdmin;
}

export function getYoutubeID(url:string){
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}