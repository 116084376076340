// ----------------------------------------------------------------------

export const title = [
  `Apply These 7 Secret Techniques To Improve Event`,
  `Believing These 7 Myths About Event Keeps You From Growing`,
];

export const sentence = [
  `Assumenda nam repudiandae rerum fugiat vel maxime.`,
  `Quis veniam aut saepe aliquid nulla.`,
  `Reprehenderit ut voluptas sapiente ratione nostrum est.`,
];

export const description = [
  `Occaecati est et illo quibusdam accusamus qui. Incidunt aut et molestiae ut facere aut.`,
];
