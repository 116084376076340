// ----------------------------------------------------------------------

export const fullName = [
  'Jayvion Simon',
  'Lucian Obrien',
  'Deja Brady',
];

export const firstName = [
  'Mossie',
  'David',
  'Ebba',
];

export const lastName = [
  'Carroll',
  'Simonis',
  'Yost',
];
