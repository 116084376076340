import { useMemo, useState, memo, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  Box,
} from '@mui/material';

// components
import Iconify from '../iconify';
import { IconButtonAnimate } from '../animate';

function NavHomeIcon() {
  const navigate = useNavigate();

  return (
    <Box>
      <IconButtonAnimate onClick={() => navigate('/', { replace: true })}>
        <Iconify icon="tabler:home" />
      </IconButtonAnimate>
    </Box>
  );
}

export default memo(NavHomeIcon);