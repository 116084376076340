// @mui
import { enUS, zhHK, zhCN, jaJP } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------
export enum LangType {
  EN = 'en',
  CN = 'cn',
  HK = 'hk',
  JA = 'ja',
}
export const allLangs = [
  {
    label: 'English',
    value: LangType.EN,
    systemValue: enUS,
    browserLangs: ['en']
  },
  {
    label: '简体中文',
    value: LangType.CN,
    systemValue: zhCN,
    browserLangs: ['zh', 'zh-CN']
  },
  {
    label: '繁體中文',
    value: LangType.HK,
    systemValue: zhHK,
    browserLangs: ['zh-HK', 'zh-TW']
  },
  {
    label: '日本語',
    value: LangType.JA,
    systemValue: jaJP,
    browserLangs: ['ja']
  },
];
const browserLang = navigator?.language || (navigator as any)?.userLanguage; 
export const defaultLang = allLangs.find((langInfo) => langInfo.browserLangs.includes(browserLang)) || allLangs[0]; // English
