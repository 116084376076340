// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Stack, Rating, FormLabel, FormLabelProps, FormControlLabelProps, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  labelSx?: FormLabelProps['sx'];
  label?: string;
  helperText?: React.ReactNode;
}

export default function RHFRating({ name, label, labelSx, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <Stack flexDirection="row" alignItems="center">
            {label && (
              <FormLabel component="legend" sx={{
                typography: 'body2',
                pr: 1,
                ...labelSx,
              }}>
                {label}
              </FormLabel>
            )}

            <Rating {...field} />
          </Stack>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}
