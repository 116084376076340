import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/CheckEmailPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const VerifyUserPage = Loadable(lazy(() => import('../pages/auth/VerifyUserPage')));
export const SuccessRequestPage = Loadable(lazy(() => import('../pages/auth/SuccessRequestPage')));

// DICTATION: App
export const ChannelsPage = Loadable(lazy(() => import('../pages/dictation/ChannelsPage')));
export const ElementsPage = Loadable(lazy(() => import('../pages/dictation/ElementsPage')));
export const DetailPage = Loadable(lazy(() => import('../pages/dictation/DetailPage')));
// FAVORITES
export const FavoritesPage = Loadable(lazy(() => import('../pages/favorites/ElementsPage')));
export const FavoritesElementPage = Loadable(lazy(() => import('../pages/favorites/ElementDetailPage')));

// ADMIN: App
export const AdminChannelsPage = Loadable(lazy(() => import('../pages/admin/ChannelsPage')));
export const EditChannelPage = Loadable(lazy(() => import('../pages/admin/EditChannelPage')));
export const CreateChannelPage = Loadable(lazy(() => import('../pages/admin/CreateChannelPage')));
export const AdminElementsPage = Loadable(lazy(() => import('../pages/admin/ElementsPage')));
export const CreateElementPage = Loadable(lazy(() => import('../pages/admin/CreateElementPage')));
export const EditElementPage = Loadable(lazy(() => import('../pages/admin/EditElementPage')));
export const ViewElementPage = Loadable(lazy(() => import('../pages/admin/ElementDetailPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const FaqsPage = Loadable(lazy(() => import('../pages/FaqsPage')));
export const DocsPage = Loadable(lazy(() => import('../pages/DocsPage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const PricingPage = Loadable(lazy(() => import('../pages/PricingPage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const PaymentSuccessPage = Loadable(lazy(() => import('../pages/PaymentSuccessPage')));
export const PaymentCanceledPage = Loadable(lazy(() => import('../pages/PaymentCanceledPage')));