//
import { useMemo } from 'react';
import Image from '../../image';
//
import { CustomFile } from '../types';
import { fileData, fileFormat, fileThumb } from '../../file-thumbnail/utils';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null;
};

export default function SingleFilePreview({ file }: Props) {
  if (!file) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const format = useMemo(() => {
    const { path = '', preview = '' } = fileData(file as File);
    return fileFormat(path || preview);
  }, [file]);
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fileUrl = useMemo(() => {
    const suffix = typeof file === 'string' && file.split('.').slice(-1)[0];
    if (format === 'image' && suffix !== 'svg') {
      return typeof file === 'string' ? file : file.preview;
    }
    return fileThumb(format, typeof file === 'string' ? file : undefined);
  }, [file, format]);

  return (
    format === 'image' ? (
      <Image
        component="img"
        alt="file preview"
        src={fileUrl}
        sx={{
          top: 8,
          left: 8,
          zIndex: 8,
          borderRadius: 1,
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
        }}
      />
    ) : (
      <Image
        component="img"
        alt="file preview"
        src={fileUrl}
        sx={{
          top: 8,
          right: {
            sm: '33%',
          },
          zIndex: 8,
          borderRadius: 1,
          position: 'absolute',
          width: 'auto',
          height: 'calc(100% - 16px)',
        }}
      />
    )
  );
}
