import { Helmet } from 'react-helmet-async';
// sections
import Login from '../../sections/auth/Login';
// locales
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { translate } = useLocales();
  return (
    <>
      <Helmet>
        <title> {`${translate('login.page_title')}`} | Learmonade</title>
      </Helmet>

      <Login />
    </>
  );
}
