import { Translation } from 'react-i18next';
import { LangType } from '../../locales/config-lang';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    '/assets/icons/platforms/ic_sketch.svg',
    '/assets/icons/platforms/ic_figma.svg',
    '/assets/icons/platforms/ic_js.svg',
    '/assets/icons/platforms/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    subscription: <Translation>{(translate) => translate('pricing.basic')}</Translation>,
    priceInfo: {
      [LangType.EN]: {
        price: 2.99,
        priceSign: '$',
      },
      [LangType.CN]: {
        price: 19,
        priceSign: '¥',
      }
    },
    caption: <Translation>{(translate) => translate('pricing.one_month_plan')}</Translation>,
    lists: [
      { content: <Translation>{(translate) => translate('pricing.unlimited_credits')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.latest_materials')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.auto_checking')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.dictation_report')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.one_month_money_saving')}</Translation>, isAvailable: false },
      { content: <Translation>{(translate) => translate('pricing.one_month_fixed_price')}</Translation>, isAvailable: false },
    ],
    labelAction: <Translation>{(translate) => translate('pricing.choose_basic')}</Translation>,
  },
  {
    subscription: <Translation>{(translate) => translate('pricing.starter')}</Translation>,
    priceInfo: {
      [LangType.EN]: {
        price: 6.99,
        oldPrice: 8.97,
        priceSign: '$',
      },
      [LangType.CN]: {
        price: 49,
        oldPrice: 57,
        priceSign: '¥',
      }
    },
    caption: <Translation>{(translate) => translate('pricing.three_months_plan')}</Translation>,
    lists: [
      { content: <Translation>{(translate) => translate('pricing.unlimited_credits')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.latest_materials')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.auto_checking')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.dictation_report')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.three_months_money_saving')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.three_months_fixed_price')}</Translation>, isAvailable: true },
    ],
    labelAction: <Translation>{(translate) => translate('pricing.choose_starter')}</Translation>,
  },
  {
    subscription: <Translation>{(translate) => translate('pricing.premium')}</Translation>,
    priceInfo: {
      [LangType.EN]: {
        price: 12.99,
        oldPrice: 17.94,
        priceSign: '$',
      },
      [LangType.CN]: {
        price: 89,
        oldPrice: 114,
        priceSign: '¥',
      }
    },
    caption: <Translation>{(translate) => translate('pricing.six_months_plan')}</Translation>,
    lists: [
      { content: <Translation>{(translate) => translate('pricing.unlimited_credits')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.latest_materials')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.auto_checking')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.dictation_report')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.six_months_money_saving')}</Translation>, isAvailable: true },
      { content: <Translation>{(translate) => translate('pricing.six_months_fixed_price')}</Translation>, isAvailable: true },
    ],
    labelAction: <Translation>{(translate) => translate('pricing.choose_premium')}</Translation>,
  },
];
