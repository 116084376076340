// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  nav: {
    home: `主页`,
    docs: `文档`,
    login_now: `立即登录`, 
    hi: `你好`,
    docs_description: `需要帮忙？\n 请查看如何使用Learmonade.`,
    documentation: `新手文档`,
    dictation: `听写`,
    favorites: `收藏`,
  },
  header: {
    need_help: `需要帮助?`,
    logout: `退出登录`,
    unlimited: `无限制`,
    upgrade_plan: `升级计划`,
    expiration_date: `有效期限`,
    your_credits: `你的额度:`,
    light_mode: `日间模式`,
    dark_mode: `夜间模式`,
    logout_error: `无法登录!`,
  },
  error: {
    email_already_exist: `此电子邮件已被注册。`,
    email_or_pwd_invalid: `邮箱或密码不正确。`,
    error_401: `无效状态，请重新加载页面或重新登录!`,
    error_429: `请求太多，请稍后再试!`,
    error_something_wrong: `出错了，请再试一次!`,
  },
  home: {
    page_title: `学习语言的起点`,
    title: `与我们一起 \n 开启你的听练学习`,
    description: `这是你语言学习的起点, 定制的学习素材帮助你更好更高效的学习语言。`,
    start_free: `免费开始`,
    help_title: `Learmonade如何 \n 帮助你?`,
    help_subtitle_1: `素材丰富`,
    help_description_1: `各种听写材料包括视频, 音频不间断持续更新。`,
    help_subtitle_2: `趣味性`,
    help_description_2: `创新视频模式助你远离枯燥的学习方式。`,
    help_subtitle_3: `自动批改`,
    help_description_3: `结合学习报告的自动批改功能, 轻松复习重点句子。`,
    tutorial_subtitle: `指引文档`,
    tutorial_title: `易于学习!`,
    tutorial_description: `阅读新手教程文档, 了解如何使用Learmonade!`,
    tutorial_btn: `立即启程`,  
    report_subtitle: `专业工具`,  
    report_title: `报告预览`, 
    theme_mode_subtitle: `易于切换的主题模式`, 
    theme_mode_title: `夜间模式`, 
    theme_mode_description: `夜间模式帮助你在夜间学习时减轻眼睛负担`, 
    materials_subtitle: `简洁 & 清晰`, 
    materials_title: `强大的资源库支持, 持续更新的学习素材`, 
    bottom_title: `立即开启 \n Learmonade学习之旅`, 
    start_now: `立即开始`, 
  },
  login: {
    welcome: `Hi, 欢迎回来`,
    page_title: `登录`, 
    title: `登录Learmonade`,
    new_user: `新用户?`, 
    create_account: `创建账号`, 
    email: `邮箱地址`, 
    password: `密码`, 
    forget_password: `忘记密码?`, 
    login: `登录`, 
    or: `或`, 
    google_login: `Google账号登录`, 
    require_email: `请填写邮件地址`,
    require_valid_email: `请输入有效的邮箱地址`,
    require_password: `请填写密码`,
  },
  register: {
    welcome: `Learmonade | 开启高效学习之旅`,
    title: `开启你的学习旅程.`,
    page_title: `注册`,
    already_has_account: `已有账号?`,
    sign_in: `登录`,
    first_name: `姓氏`,
    last_name: `名字`,
    email_address: `邮箱地址`,
    password: `密码`,
    password_confirm: `确认密码`,
    create_account: `创建账号`,
    agreement: `注册后，我同意`,
    service_terms: `服务条款`,
    privacy_policy: `隐私政策`,
    and: `和`,
    require_first_name: `请填写姓氏`,
    require_last_name: `请填写名字`,
    require_email: `请填写邮箱地址`,
    require_valid_email: `请填写有效的邮箱地址`,
    require_password: `请填写密码`,
    require_password_confirm: `请确认两次密码相同`,
  },
  verify_code: {
    page_title: `用户验证`,
    title: `请查收您的电子邮箱!`,
    sending: `邮件发送中...`,
    description: `我们已将邮箱认证链接发送到您的邮箱，请检查并完成您的账户验证。`,
    cannot_receive: `无法收到邮件? 请检查您的`,
    spam_email: `垃圾邮箱`,
    and: `或`,
    resend: `点击重发`,
    return: `返回登录`,
    errors: `请在浏览器url地址末尾加上'?email=xxx@xx'(你的邮箱地址)作为浏览器url的后缀并刷新页面。`,
    success: `邮件发送成功!`
  },
  verify_user: {
    page_title: `用户验证`,
    success_title: `恭喜!`,
    success_description: `您的帐号已注册成功!请登录Learmonade，开启学习。`,
    verifying: `验证中...`,
    error_title: `对不起, 出错了, 请再试一次!`,
    error_description: `我们暂时无法验证您的账号。请刷新页面或直接登录您的账号以重新发送验证邮件。`,
    login: `返回登录`,
  },
  new_password: {
    page_title: `设置新密码`,
    title: `确认成功!`,
    description: `请在下面输入框中设置您的新密码。`,
    password: `密码`,
    confirm_password: `再次输入密码`,
    update_password: `更新密码`,
    return_login: `返回登录`,
    require_eight_password_chars: `请输入至少8个字符的密码`,
    require_password: `请填写密码`,
    require_password_confirm: `请再次填写密码`,
    require_password_match: `两次密码输入不符`,
    toast_change_success: `密码更改成功!`,
  },
  reset_password: {
    page_title: `重置密码`,
    title: `忘记密码?`,
    description: `请输入您帐户的电子邮件地址，我们将通过电子邮件向您发送重置密码的链接。`,
    email_address: `邮箱地址`,
    send_request: `确认发送`,
    return_sign_in: `返回登录`,
    require_email: `请填写邮箱地址`,
    require_valid_email: `请填写有效的邮箱地址`,
  },
  success_request: {
    page_title: `发送成功`,
    title: `邮件发送成功!`,
    description_1: `我们已经向您的邮箱发送了确认邮件。`,
    description_2: `请检查收件箱中的确认链接以重置密码。`,
    do_not_receive: `无法收到邮件?`,
    resend_email: `重新发送`,
    return_sign_in: `返回登录`,
  },
  pricing: {
    page_title: `价格`,
    title_1: `根据您的学习需求`,
    title_2: `选择计划`,
    description: `选择您的计划，开启轻松的语言学习之旅`,
    popular: `热门`,
    basic: `基础`,
    starter: `进阶`,
    premium: `高级`,
    one_month_plan: `1个月套餐`,
    three_months_plan: `3个月套餐`,
    six_months_plan: `6个月套餐`,
    unlimited_credits: `无限制的素材学习额度`,
    latest_materials: `支持最新的学习素材(持续更新)`,
    auto_checking: `自动听写校对`,
    dictation_report: `支持听写模式`,
    one_month_money_saving: `节省金钱`,
    three_months_money_saving: `节省金钱 (多至 ¥8)`,
    six_months_money_saving: `节省金钱 (多至 ¥25)`,
    one_month_fixed_price: `固定价格享受最新功能`,
    three_months_fixed_price: `固定价格享受最新功能 (有效期3个月)`,
    six_months_fixed_price: `固定价格享受最新功能 (有效期6个月)`,
    choose_basic: `选择基础版`,
    choose_starter: `选择进阶版`,
    choose_premium: `选择高级版`,
  },
  faqs: {
    page_title: `常见问题`,
    title: `常见问题`,
    question_1: `如何联系Learmonade以获得进一步的支持?`,
    answer_1: `联系邮箱地址:`,
    question_2: `有Learmonade的新手文档吗?`,
    answer_2_please: `请`,
    answer_2_click: `点击`,
    answer_2_read_details: `阅读详情。`,
  },
  docs: {
    page_title_prefix: `新手文档:`,
    title: `如何使用Learmonade?`,
    nav_title: `Learmonade | 新手文档`,
    nav_home: `主页`,
    nav_docs: `文档`,
    steps_title: `如何使用Learmonade?`,
    steps_caption: `Learmonade | 我们一起开始语言学习吧~`,
    steps_description: `本指南将介绍用如何用Learmonade进行高效语言学习以及基本介绍学习流程。对于初学者来说，这可以帮助您快速开始学习。让我们开始吧!`,
    step_1_title: `1. 基础学习流程介绍`,
    step_1_description: `在这个视频中，我们将介绍Learmonade的基本学习流程, 这种学习方法可以帮助初学者利用Learmonade高效学习英语以及提升打字速度。`,
    step_2_title: `2. 蓝牙设备设置`,
    step_2_description_1: `如果使用蓝牙设备如蓝牙耳机进行学习，请确保您已在设置中打开 `,
    step_2_description_2: `Bluetooth Device`,
    step_2_description_3: `(蓝牙设备)选项.`,
    step_3_title: `3. 键盘快捷键`,
    step_3_description: `Learmonade学习界面基础键盘快捷键如下: `,
    step_4_title: `4. 如何关闭视频字幕? (针对YouTube观看的用户)`,
    step_4_description: "第1步，在设置对话框中打开`Showing Video Controls` (显示视频控件)选项。第二步，点击关闭YouTube下方字幕设置，如下图2所示。步骤3，重新加载页面。",
    step_5_title: `5. 联系我们`,
    step_5_description: `如有任何问题，欢迎随时联系我们:`,
  },
  dictation: {
    first_visit_title: `新手访问指南`,
    first_visit_description: `Learmonade快速学习指南:`,
    first_visit_list_1_1: `每月3个素材额度`,
    first_visit_list_1_2: `给予免费用户，每个素材需要花费一个额度，额度会在`,
    first_visit_list_1_3: `每月1号重置`,
    first_visit_list_1_4: `，可以通过点击右上角用户头像来查看本月剩余额度。`,
    first_visit_list_2_1: `付费用户`,
    first_visit_list_2_2: `拥有无限额度，可以访问平台所有的学习素材。`,
    first_visit_list_3_1: `如果使用蓝牙耳机学习，在学习前请确保已打开`,
    first_visit_list_3_2: `Bluetooth Device`,
    first_visit_list_3_3: `(蓝牙设备)设置。`,
    first_visit_list_4_1: `请阅读`,
    first_visit_list_4_2: `文档`,
    first_visit_list_4_3: `以了解Learmonade基本学习流程和更多细节，强烈建议您在学习之前阅读文档。`,
    first_visit_list_5: `基础快捷键: Tab: 重听当前句, ↑键: 播放上一句, ↓键: 播放下一句`,
    
    settings_title: `听写设置`,
    settings_showing_video: `显示视频`,
    settings_showing_video_controls: `显示视频控件`,
    settings_bluetooth_device: `蓝牙设备`,
    settings_reset_learning: `重置素材`,
    settings_reset: `重置`,

    typing_area_placeholder: `输入你的答案`,
    typing_area_loading: `正在加载你的学习进度...`,

    no_credits_title: `额度不足`,
    no_credits_content_1: `您的月度素材额度已用完，请等待每月1号重置或升级您的计划以获得`,
    no_credits_content_2: `无限额度`,
    no_credits_later: `稍后`,
    no_credits_upgrade_plan: `升级计划`,

    reset_learning_title: `重置学习记录`,
    reset_learning_content: `你还未没有当前素材的学习，仍然想重置学习记录吗?`,
    reset_learning_no: `忽略`,
    reset_learning_yes: `重置`,

    auto_play_confirm_title: `自动播放模式`,
    auto_play_confirm_content: `在自动播放模式下，句子会自动按顺序播放，直到该素材结束。您要开启自动播放模式吗?`,
    auto_play_confirm_yes: `是的`,
    auto_play_confirm_no: `不需要`,

    complete_learning_title: `恭喜!`,
    complete_learning_content: "您已完成本素材的学习! 如果您想重新学习此内容，请前往「设置」选择重置学习记录。",
    complete_learning_ok: `好的`,

    typing_mode_click_or_press: `点击或按Tab键激活...`,
    typing_mode_metrics: `指标:`,
    typing_mode_speed: `速度:`,
    typing_mode_accuracy: `准确率:`,
    typing_mode_typing_settings: `打字设置:`,
    typing_mode_ignore_case: `忽略大小写:`,
    typing_mode_ignore_punctuation: `忽略标点:`,

    report_mode_title: `学习报告`,
    report_mode_total_score: `总分`,
    report_mode_excellent: `优秀`,
    report_mode_good: `不错`,
    report_mode_poor: `有待提升`,
    report_mode_under_60: `低于60`,
    report_mode_compare: `对比:`,
    report_mode_your_answer: `你的回答:`,
    report_mode_score: `得分:`,
    report_mode_accuracy: `正确率:`,

    reading_mode: `阅读模式`,
    typing_mode: `打字模式`,
    dictation_mode: `听写模式`,
    report_mode: `报告模式`,
    close: `关闭`,
  },
  admin: {
    settings_title: `管理设置`,
    settings_hidden_channels: `隐藏未上线的频道:`,
    settings_hidden_elements: `隐藏未上线的素材:`,
    close: `关闭`, 
  },
  page_error: {
    title: `出了点问题!`,
    description: `遇到一个错误，请尝试刷新页面。`,
    reload: `刷新页面`,
  },
  page_404: {
    title: `对不起，页面不存在!`,
    description: `我们找不到您要的页面，请检查页面地址是否拼写正确。`,
    go_to_home: `返回主页`,
  },
  payment_success: {
    page_title: `支付成功`,
    title: `您的付款交易已成功`,
    description: `请点击开启您的学习!`,
    go_to_explore: `开启学习`,
  },
  payment_canceled: {
    page_title: `付款失败`,
    title: `您的付款交易被取消`,
    description: `如需要帮助，请邮件联系我们!`,
    go_to_home: `返回主页`,
  }
};

export default cn;
