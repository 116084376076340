import { useMemo, useState, memo, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  Box,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';
import { IconButtonAnimate } from '../../../components/animate';
// routes
import { PATH_ADMIN } from '../../../routes/paths';

function NavBackIcon() {
  const navigate = useNavigate();
  const { elementId, channelId } = useParams();
  const { pathname } = useLocation();

  const backPath = useMemo(() => ({
    [PATH_ADMIN.channels.create]: PATH_ADMIN.channels.list,
    [PATH_ADMIN.channels.edit(Number(channelId))]: PATH_ADMIN.channels.list,
    [PATH_ADMIN.elements.list(Number(channelId))]: PATH_ADMIN.channels.list,
    [PATH_ADMIN.elements.create(Number(channelId))]: PATH_ADMIN.elements.list(Number(channelId)),
    [PATH_ADMIN.elements.edit(Number(channelId), Number(elementId))]: PATH_ADMIN.elements.list(Number(channelId)),
    [PATH_ADMIN.elements.view(Number(channelId), Number(elementId))]: PATH_ADMIN.elements.list(Number(channelId)),
  }[pathname] || ''), [pathname, elementId, channelId])

  return (
    <Box>
      <IconButtonAnimate onClick={() => backPath ? navigate(backPath, { replace: true }) : navigate(-1)}>
        <Iconify icon="ion:arrow-back" />
      </IconButtonAnimate>
    </Box>
  );
}

export default memo(NavBackIcon);