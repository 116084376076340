// @ts-ignore
import { track } from '@vercel/analytics';
import { getStorage, StorageType} from './storage';

type AllowedPropertyValues = string | number | boolean | null;
/**
 * Rule: page name + function name(ex: doc button) + action(ex: click)
*/
export enum PageType {
    Header = 'header',
    Home = 'home',
    Dictation = 'dictation',
    Channels = 'channels',
    Elements = 'elements',
    Element = 'element',
    Doc = 'doc',
    Pricing = 'pricing',
}
export enum ComponentType {
    Icon = 'icon',
    Button = 'button',
    MenuItem = 'menu_item',
    SelectItem = 'select_item',
    DialogConfirmButton = 'dialog_confirm_button',
}
export enum ActionType {
    Click = 'click',
    Duration = 'duration',
    Reload = 'reload',
}

export const TrackingType = {
    // Home Page
    HomeLoginNowButtonClick: `${PageType.Home}_login_now_${ComponentType.Button}_${ActionType.Click}`,
    HomeStartForFreeButtonClick: `${PageType.Home}_start_for_free_${ComponentType.Button}_${ActionType.Click}`,
    HomeNavDocClick: `${PageType.Home}_nav_doc_${ComponentType.Button}_${ActionType.Click}`,
    HomeNavDiscordClick: `${PageType.Home}_nav_discord_${ComponentType.Button}_${ActionType.Click}`,
    HomePageInitialReload: `${PageType.Home}_initial_${ActionType.Reload}`,

    // Header
    HeaderDarkModeItemClick: `${PageType.Header}_dark_mode_${ComponentType.MenuItem}_${ActionType.Click}`,
    HeaderLogoutItemClick: `${PageType.Header}_logout_${ComponentType.MenuItem}_${ActionType.Click}`,
    HeaderUpgradePlanItemClick: `${PageType.Header}_upgrade_plan_${ComponentType.MenuItem}_${ActionType.Click}`,

    // Dictation Page
    DictationHowToUseButtonClick: `${PageType.Dictation}_how_to_use_${ComponentType.Button}_${ActionType.Click}`,
    DictationNoCreditDialogUpgradePlanButtonClick: `${PageType.Dictation}_no_credit_dialog_upgrade_plan_${ComponentType.Button}_${ActionType.Click}`,

    // Channels Page + channel_id
    ChannelsCardClick: `${PageType.Channels}_card_${ComponentType.Button}_${ActionType.Click}`,

    // Elements Page + element_id
    ElementsCardClick: `${PageType.Elements}_card_${ComponentType.Button}_${ActionType.Click}`,

    // Element Page + element_id
    // ElementStartToEndDuration: `${PageType.Element}_start_to_end_${ActionType.Duration}`, TODO
    ElementSettingsIconClick: `${PageType.Element}_settings_${ComponentType.Icon}_${ActionType.Click}`,
    ElementHelpIconClick: `${PageType.Element}_help_${ComponentType.Icon}_${ActionType.Click}`,
    ElementSettingsDialogResetLearningButtonClick: `${PageType.Element}_settings_dialog_reset_learning_${ComponentType.Button}_${ActionType.Click}`,
    ElementPlackBackRateItemClick: `${PageType.Element}_playback_rate_${ComponentType.SelectItem}_${ActionType.Click}`,
    ElementAutoPlayingModeClick: `${PageType.Element}_auto_playing_mode_${ComponentType.Button}_${ActionType.Click}`,
    ElementResetLearningConfirmButtonClick: `${PageType.Element}_reset_learning_${ComponentType.DialogConfirmButton}_${ActionType.Click}`,

    // Pricing Page
    PricingPageLoad: `${PageType.Pricing}_page_load`,
  } as const;

type Keys = keyof typeof TrackingType;
export const tracking = (type: typeof TrackingType[Keys],  properties?: Record<string, AllowedPropertyValues>) => {
    const IPAddress = localStorage.getItem('ipLocation')?.toLocaleUpperCase();
    const from = getStorage(StorageType.from);
    const basicData:any = { 
        path: window.location.href,
        ip_location: String(IPAddress),
        user_agent: window.navigator.userAgent,
        user_id: getStorage(StorageType.userId),
    };
    if (from) {
        basicData.from = from;
    }
    track(type, {
        ...basicData,
        ...properties,
    });
}