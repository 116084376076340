import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';
import { getAvailableAddress } from './address';
import { getLang } from './locale';
// locales
import i18n from '../locales/i18n';

// ----------------------------------------------------------------------

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({ 
  timeout: 60000,
  baseURL: getAvailableAddress(HOST_API_KEY),
});

axiosInstance.interceptors.request.use((config) => {
  // Add dynamic headers
  config.headers['User-Language'] = getLang();
  return config;
}, 
(error) => Promise.reject(error));

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('accessToken');
      error.response.data.detail = `${i18n.t('error.error_401')}`;
      return Promise.reject(error.response);
    };
    if (error?.response?.status === 429) return `${i18n.t('error.error_429')}`;
    return Promise.reject((error.response) || `${i18n.t('error.error_something_wrong')}`);
  }
);

export default axiosInstance;
