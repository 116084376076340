import { GoogleLogin } from '@react-oauth/google';
// @mui
import { Box, Divider, IconButton, Button, Stack } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import { useSnackbar } from '../../components/snackbar';
// locales
import { useLocales } from '../../locales';
// utils
import { errorsParse } from '../../utils/errorsParse';
// config
import { GITHUB_CLIENT_ID, GITHUB_APP_SECRET } from '../../config-global';

// ----------------------------------------------------------------------
const REDIRECT_URI = `${window.origin}/auth/login`;
export default function AuthWithSocial() {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const { 
    loginWithGoogle, 
    loginWithGithub, 
    loginWithTwitter,
    isSocialLogining,
    setIsSocialLogining } = useAuthContext();

  const handleGoogleLogin = async (accessToken:string) => {
    try {
      if (loginWithGoogle) {
        await loginWithGoogle(accessToken);
      }
      console.log('GOOGLE LOGIN');
    } catch (error) {
      enqueueSnackbar(errorsParse(error), {variant: 'error' });
    } finally {
      setIsSocialLogining(false);
    }
  };

  const handleGithubLogin = async (accessToken:string) => {
    try {
      if (loginWithGithub) {
        await loginWithGithub(accessToken);
      }
      console.log('GITHUB LOGIN');
    } catch (error) {
      enqueueSnackbar(errorsParse(error), {variant: 'error' });
    } finally {
      setIsSocialLogining(false);
    }
  };

  const handleTwitterLogin = async () => {
    try {
      if (loginWithTwitter) {
        loginWithTwitter();
      }
      console.log('TWITTER LOGIN');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        {`${translate('login.or')}`}
      </Divider>

      <Stack
        spacing={2}
        direction="row" 
        justifyContent="center" >

        <Button
          color="inherit"
          size="medium"
          variant="outlined"
          disabled={isSocialLogining}
          sx={{
            borderRadius: '20px',
          }}
          startIcon={<Iconify icon="flat-color-icons:google" />}
        >
          {`${translate('login.google_login')}`}
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
          }}>
            <GoogleLogin
              size="large"
              onSuccess={(data:any) => {
                handleGoogleLogin(data?.credential);
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </Box>
        </Button>

        {/* <IconButton sx={{cursor: 'pointer'}} onClick={handleGoogleLogin}>
          <Iconify icon="eva:google-fill" color="#DF3E30" />
        </IconButton>

        <IconButton sx={{cursor: 'pointer'}} onClick={handleGithubLogin}>
          <Iconify icon="eva:github-fill" color="inherit" />
        </IconButton>

        <IconButton sx={{cursor: 'pointer'}} onClick={handleTwitterLogin}>
          <Iconify icon="eva:twitter-fill" color="#1C9CEA" />
        </IconButton>

        <IconButton sx={{cursor: 'pointer'}} onClick={handleTwitterLogin}>
          <Iconify icon="eva:facebook-fill" color="#1877F2" />
        </IconButton> */}
      </Stack>
    </div>
  );
}
