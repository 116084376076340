// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ja = {
  nav: {
    home: `ホームページ`,
    docs: `ガイド`,
    login_now: `ログイン`, 
    hi: `こんにちは`,
    docs_description: `助けが必要？\n Learmonadeの使い方をご確認ください.`,
    documentation: `使い方？`,
    dictation: `聴覚`,
    favorites: `集める`,
  },
  header: {
    need_help: `助けが必要ですか?`,
    logout: `ログアウト`,
    unlimited: `無制限`,
    upgrade_plan: `アップグレードプラン`,
    expiration_date: `有効期間`,
    your_credits: `あなたの限界:`,
    light_mode: `デイモード`,
    dark_mode: `ナイトモード`,
    logout_error: `ログインできません!`,
  },
  error: {
    email_already_exist: `このメールはすでに登録されています。`,
    email_or_pwd_invalid: `メールアドレスまたはパスワードが間違っています。`,
    error_401: `無効なステータスです。ページをリロードするか、再度ログインしてください!`,
    error_429: `リクエストが多すぎます。後でもう一度お試しください。`,
    error_something_wrong: `エラーが発生しました。もう一度お試しください!`,
  },
  home: {
    page_title: `語学学習のスタート地点`,
    title: `一緒に \n 聞いて真似して学習を \n 始めましょう`,
    description: `これは言語学習の出発点です。カスタマイズされた学習教材は、言語をより良く、より効率的に学習するのに役立ちます。`,
    start_free: `無料で始める`,
    help_title: `Learmonade はあなたのた \n めに何ができるでしょうか?`,
    help_subtitle_1: `豊富な素材`,
    help_description_1: `動画や音声を含むさまざまなディクテーション教材が継続的に更新されます。`,
    help_subtitle_2: `面白い`,
    help_description_2: `革新的なビデオ モードは、退屈な学習方法から遠ざけるのに役立ちます。`,
    help_subtitle_3: `自動修正`,
    help_description_3: `学習レポートの自動修正機能と組み合わせることで、重要な文章の復習が簡単になります。 `,
    tutorial_subtitle: `ガイドドキュメント`,
    tutorial_title: `学びやすい!`,
    tutorial_description: `Learmonade の使用方法については、初心者向けチュートリアル ドキュメントを読んでください!`,
    tutorial_btn: `今すぐ開始`,
    report_subtitle: `プロフェッショナル ツール`,
    report_title: `レポートのプレビュー`,
    theme_mode_subtitle: `テーマモードを簡単に切り替える`,
    theme_mode_title: `ナイトモード`,
    theme_mode_description: `ナイトモードは夜間の勉強時の目の負担を軽減します`,
    materials_subtitle: `シンプル & クリア`,
    materials_title: `強力なリソース ライブラリ、継続的に更新されるマテリアル`,
    bottom_title: `Learmonade 学習の旅 \n を今すぐ始めましょう`,
    start_now: `今すぐ開始`, 
  },
  login: {
    welcome: `Hi, おかえり`,
    page_title: `ログイン`,
    title: `ログインLearmonade`,
    new_user: `新しいユーザー?`,
    create_account: `アカウントを作成する`,
    email: `電子メールアドレス`,
    password: `パスワード`,
    forget_password: `パスワードを忘れる?`,
    login: `ログイン`,
    or: `または`, 
    google_login: `Googleアカウントログイン`, 
    require_email: `メールアドレスをご記入ください`,
    require_valid_email: `有効なメールアドレスを入力してください`,
    require_password: `パスワードを入力してください`,
  },
  register: {
    welcome: `Learmonade | 効率的な学習を始めましょう`,
    title: `学習の旅を始めましょう.`,
    page_title: `登録する`,
    already_has_account: `すでにアカウントをお持ちです?`,
    sign_in: `ログイン`,
    first_name: `苗字`,
    last_name: `名前`,
    email_address: `電子メールアドレス`,
    password: `パスワード`,
    password_confirm: `パスワードの確認`,
    create_account: `アカウントの作成`,
    agreement: `登録後、同意します`,
    service_terms: `利用規約`,
    privacy_policy: `プライバシー ポリシー`,
    and: `と`,
    require_first_name: `姓を入力してください`,
    require_last_name: `名前を入力してください`,
    require_email: `メールアドレスを入力してください`,
    require_valid_email: `有効な電子メール アドレスを入力してください`,
    require_password: `パスワードを入力してください`,
    require_password_confirm: `パスワードが2回同じであることを確認してください`,
  },
  verify_code: {
    page_title: `ユーザー認証`,
    title: `メールを確認してください!`,
    sending: `電子メールが送信されています...`,
    description: `メール認証リンクをメールに送信しました。確認してアカウント認証を完了してください。`,
    cannot_receive: `メールを受信できませんか? `,
    spam_email: `迷惑メールボックス`,
    and: `または`,
    resend: `クリックして再送信`,
    return: `ログインに戻る`,
    errors: `ブラウザの URL アドレスの接尾辞として '?email=xxx@xx' (電子メール アドレス) を追加し、ページを更新してください。`,
    success: `メールは正常に送信されました!`
  },
  verify_user: {
    page_title: `ユーザー認証`,
    success_title: `おめでとうございます!`,
    success_description: `アカウントは正常に登録されました。学習を開始するには、Learmonade にログインしてください。`,
    verifying: `検証中...`,
    error_title: `申し訳ありませんが、問題が発生しました。もう一度お試しください。`,
    error_description: `現在、あなたのアカウントを確認できません。ページを更新するか、アカウントに直接ログインして確認メールを再送信してください`,
    login: `ログインに戻る`,
  },
  new_password: {
    page_title: `新しいパスワードを設定`,
    title: `確認成功!`,
    description: `下の入力ボックスに新しいパスワードを設定してください。`,
    password: `パスワード`,
    confirm_password: `パスワードをもう一度入力してください`,
    update_password: `パスワードを更新`,
    return_login: `ログインを返す`,
    require_eight_password_chars: `8 文字以上のパスワードを入力してください`,
    require_password: `パスワードを入力してください`,
    require_password_confirm: `パスワードをもう一度入力してください`,
    require_password_match: `2 つのパスワード入力が一致しません`,
    toast_change_success: `パスワードが正常に変更されました!`,
  },
  reset_password: {
    page_title: `パスワードをリセット`,
    title: `リセットしますか？`,
    description: `アカウントの電子メール アドレスを入力してください。パスワードをリセットするためのリンクが電子メールで送信されます。`,
    email_address: `電子メール アドレス`,
    send_request: `送信を確認`,
    return_sign_in: `ログインに戻る`,
    require_email: `メールアドレスを入力してください`,
    require_valid_email: `有効な電子メール アドレスを入力してください`,
  },
  success_request: {
    page_title: `正常に送信されました`,
    title: `メールは正常に送信されました!`,
    description_1: `あなたのメールアドレスに確認メールを送信しました。`,
    description_2: `パスワードをリセットするための確認リンクがないか、受信箱を確認してください。`,
    do_not_receive: `メールを受信できませんか?`,
    resend_email: `再送信`,
    return_sign_in: `ログインに戻る`,
  },
  pricing: {
    page_title: `価格`,
    title_1: `学習ニーズに応じて`,
    title_2: `プランを選択`,
    description: `プランを選択して、リラックスした言語学習の旅を始めましょう`,
    popular: `人気`,
    basic: `基本`,
    starter: `上級`,
    premium: `プレミアム`,
    one_month_plan: `1 か月プラン`,
    three_months_plan: `3 か月パッケージ`,
    six_months_plan: `6 か月パッケージ`,
    unlimited_credits: `無制限の教材学習割り当て`,
    latest_materials: `最新の学習教材をサポートします (継続的に更新されます)`,
    auto_checking: `自動口述校正`,
    dictation_report: `ディクテーション モードをサポート`,
    one_month_money_saving: `お金を節約する`,
    three_months_money_saving: `お金を節約します (最大$2)`,
    six_months_money_saving: `お金を節約します (最大$5)`,
    one_month_fixed_price: `固定価格で最新機能をお楽しみください (有効期限なし)`,
    three_months_fixed_price: `固定価格で最新機能をお楽しみください (3か月間有効)`,
    six_months_fixed_price: `固定価格で最新機能をお楽しみください (6か月間有効)`,
    choose_basic: `基本バージョンを選択します`,
    choose_starter: `高度なバージョンを選択`,
    choose_premium: `プレミアム バージョンを選択`,
  },
  faqs: {
    page_title: `FAQ`,
    title: `よくある質問`,
    question_1: `さらなるサポートのために Learmonade に連絡するにはどうすればよいですか?`,
    answer_1: `連絡先メール アドレス:`,
    question_2: `Learmonade の初心者向けドキュメントはありますか?`,
    answer_2_please: `お願いします`,
    answer_2_click: `クリック`,
    answer_2_read_details: `詳細を読んでください。 `,
  },
  docs: {
    page_title_prefix: `初心者向けドキュメント:`,
    title: `Learmonadeの使い方は?`,
    nav_title: `Learmonade | 初心者向けドキュメント`,
    nav_home: `ホームページ`,
    nav_docs: `ドキュメント`,
    steps_title: `Learmonadeの使い方は?`,
    steps_caption: `Learmonade | 一緒に語学学習を始めましょう~`,
    steps_description: `このガイドでは、Learmonade を使用して効率的な言語学習を行う方法と、学習プロセスの基本的な概要を紹介します。初心者にとって、これはすぐに学習を始めるのに役立ちます。始めましょう!`,
    step_1_title: `1. 基本的な学習プロセスの紹介`,
    step_1_description: `このビデオでは、Learmonade の基本的な学習プロセスを紹介します。この学習方法は、初心者が Learmonade を使用して効率的に英語を学習し、タイピング速度を向上させるのに役立ちます。`,
    step_2_title: `2. Bluetooth デバイスの設定`,
    step_2_description_1: `Bluetooth ヘッドセットなどの Bluetooth デバイスを使用して学習する場合は、設定でオンになっていることを確認してください`,
    step_2_description_2: `Bluetooth Device`,
    step_2_description_3: `(Bluetooth デバイス) オプション。`,
    step_3_title: `3. キーボード ショートカット`,
    step_3_description: `Learmonade 学習インターフェイスの基本的なキーボード ショートカットは次のとおりです: `,
    step_4_title: `4. 動画の字幕をオフにする方法 (YouTube で視聴しているユーザー向け)`,
    step_4_description: "ステップ 1、設定ダイアログ ボックスの「Showing Video Controls」オプションをオンにします。ステップ 2、下の図 2 に示すように、YouTube の下の字幕設定をクリックしてオフにします。ステップ 3、ページをリロードします。",
    step_5_title: `5. お問い合わせ`,
    step_5_description: `ご質問がございましたら、いつでもお気軽にお問い合わせください:`,
  },
  dictation: {
    first_visit_title: `初心者訪問ガイド`,
    first_visit_description: `Learmonadeクイックスタディガイド:`,
    first_visit_list_1_1: `月あたり 3 つの材料割り当て`,
    first_visit_list_1_2: `無料ユーザーに提供します。各マテリアルにはクォータを消費する必要があります。クォータは `,
    first_visit_list_1_3: `毎月1日にリセット`,
    first_visit_list_1_4: `, 右上隅にあるユーザーのアバターをクリックすると、今月の残りの割り当てを確認できます。`,
    first_visit_list_2_1: `有料ユーザー`,
    first_visit_list_2_2: `無制限の割り当てにより、プラットフォーム上のすべての学習教材にアクセスできます。`,
    first_visit_list_3_1: `学習に Bluetooth ヘッドセットを使用する場合は、学習する前にオンになっていることを確認してください`,
    first_visit_list_3_2: `Bluetooth Device`,
    first_visit_list_3_3: `(Bluetooth デバイス) 設定。`,
    first_visit_list_4_1: `お読みください`,
    first_visit_list_4_2: `ドキュメント`,
    first_visit_list_4_3: `Learmonadeの基本的な学習プロセスと詳細を理解するには、学習する前にドキュメントを読むことを強くお勧めします。`,
    first_visit_list_5: `基本的なショートカットキー: Tab: 現在の文をもう一度聞く, ↑キー: 前の文を再生, ↓キー: 次の文を再生`,
    
    settings_title: `ディクテーション設定`,
    settings_showing_video: `ビデオを表示`,
    settings_showing_video_controls: `ビデオコントロールの表示`,
    settings_bluetooth_device: `Bluetooth デバイス`,
    settings_reset_learning: `マテリアルをリセット`,
    settings_reset: `リセット`,

    typing_area_placeholder: `答えを入力してください`,
    typing_area_loading: `学習の進捗状況を読み込んでいます...`,

    no_credits_title: `クレジットが不足しています`,
    no_credits_content_1: `毎月のクレジット クォータが使い果たされました。取得するには毎月 1 日にリセットまたはプランがアップグレードされるまでお待ちください`,
    no_credits_content_2: `無制限の制限`,
    no_credits_later: `後で`,
    no_credits_upgrade_plan: `アップグレード プラン`,

    reset_learning_title: `学習記録をリセット`,
    reset_learning_content: `現在の教材を学習していないのに、学習記録をリセットしたいですか?`,
    reset_learning_no: `無視`,
    reset_learning_yes: `リセット`,
    
    auto_play_confirm_title: `自動再生モード`,
    auto_play_confirm_content: `自動再生モードでは、素材の終わりまで文章が順番に自動的に再生されます。自動再生モードを有効にしますか?`,
    auto_play_confirm_yes: `はい`,
    auto_play_confirm_no: `不要`,

    complete_learning_title: `おめでとう！`,
    complete_learning_content: "この教材の学習は完了しました。このコンテンツを再学習したい場合は、「設定」に移動して、学習記録のリセットを選択してください。",
    complete_learning_ok: `わかりました`,

    typing_mode_click_or_press: `クリックするか Tab キー`, 
    typing_mode_metrics: `メトリクス:`,
    typing_mode_speed: `速度:`,
    typing_mode_accuracy: `精度:`,
    typing_mode_typing_settings: `入力設定:`,
    typing_mode_ignore_case: `大文字と小文字を無視します:`,
    typing_mode_ignore_punctuation: `句読点を無視する:`,

    report_mode_title: `調査レポート`,
    report_mode_total_score: `合計スコア`,
    report_mode_excellent: `優秀`,
    report_mode_good: `良い`,
    report_mode_poor: `改善予定`,
    report_mode_under_60: `60未満`,
    report_mode_compare: `比較:`,
    report_mode_your_answer: `あなたの答え:`,
    report_mode_score: `スコア:`,
    report_mode_accuracy: `精度率:`,

    reading_mode: `読み取りモード`,
    typing_mode: `タイピング モード`,
    dictation_mode: `ディクテーション モード`,
    report_mode: `レポートモード`,
    close: `閉じる`,
  },
  admin: {
    settings_title: `管理設定`,
    settings_hidden_channels: `オンラインではないチャンネルを非表示にします:`,
    settings_hidden_elements: `使用できないマテリアルを非表示にする:`,
    close: `閉じる`,
  },
  page_error: { 
    title: `何か問題が発生しました!`,
    description: `エラーが発生しました。ページを更新してください。`,
    reload: `ページを更新`,
  },
  page_404: {
    title: `申し訳ありませんが、ページは存在しません!`,
    description: `お探しのページが見つかりません。ページ アドレスのスペルが正しいかどうかを確認してください。`,
    go_to_home: `ホームページに戻る`,
  },
  payment_success: {
    page_title: `支払いが成功しました`,
    title: `あなたの支払い取引は成功しました`,
    description: `クリックして学習を始めてください!`,
    go_to_explore: `学習を開始`,
  },
  payment_canceled: {
    page_title: `支払いに失敗しました`,
    title: `あなたの支払い取引はキャンセルされました`,
    description: `サポートが必要な場合は、メールでご連絡ください!`,
    go_to_home: `ホームページに戻る`,
  }
};

export default ja;
