//
import { Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Iconify from '../../iconify';

// ----------------------------------------------------------------------

export default function FileUploading() {

  return (
    <Stack 
      justifyContent='center' 
      alignItems='center' 
      sx={{
        position: 'absolute',
        bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
        width: 1,
        height: 1,
        top: 0,
        left: 0,
        zIndex: 8,
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)',
      }}>
      <Iconify sx={{
        color: (theme) => theme.palette.grey[400],
      }} icon="line-md:uploading-loop" width={70} />
    </Stack>
  );
}
