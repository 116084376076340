// ----------------------------------------------------------------------

export const price = [
  16.19, 35.71,
];

export const rating = [
  2.5, 2,
];

export const age = [
  52, 43,
];

export const percent = [
  8.62, 86.36
];
