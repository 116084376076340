// ----------------------------------------------------------------------

export function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth';
export const ROOTS_DASHBOARD = '/dashboard';
export const ROOTS_DICTATION = '/dictation';
export const ROOTS_FAVORITES = '/favorites';
export const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  successRequest: path(ROOTS_AUTH, '/success-request'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  docsPage: '/docs',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DICTATION = {
  root: ROOTS_DICTATION,
  channels: path(ROOTS_DICTATION, '/channels'),
  elements: (channelId: number) => path(ROOTS_DICTATION, `/channel/${channelId}`),
  details: (channelId: number, elementId: number) => path(ROOTS_DICTATION, `/channel/${channelId}/element/${elementId}`),
};

export const PATH_FAVORITES = {
  root: ROOTS_FAVORITES,
  elements: path(ROOTS_FAVORITES, '/elements'),
  details: (elementId: number) => path(ROOTS_FAVORITES, `/element/${elementId}`),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  channels: {
    list: path(ROOTS_ADMIN, '/channels'),
    create: path(ROOTS_ADMIN, '/channel/create'), 
    edit: (channelId: number) => path(ROOTS_ADMIN, `/channel/edit/${channelId}`),
  },
  elements: {
    list: (channelId: number) => path(ROOTS_ADMIN, `/channel/${channelId}`),
    create: (channelId: number) => path(ROOTS_ADMIN, `/channel/${channelId}/element/create`),
    edit: (channelId: number, elementId: number) => path(ROOTS_ADMIN, `/channel/${channelId}/element/${elementId}/edit`),
    view: (channelId: number, elementId: number) => path(ROOTS_ADMIN, `/channel/${channelId}/element/${elementId}/view`),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
