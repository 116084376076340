import { useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_PAGE, PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { useSettingsContext } from '../../../components/settings';
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import SvgColor from '../../../components/svg-color';
// utils
import { tracking, TrackingType } from '../../../utils/trackingEvent';
// locales
import { useLocales } from '../../../locales';
import i18n from '../../../locales/i18n';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuthContext();

  const { translate } = useLocales();

  const OPTIONS = useMemo(() => (
    [
      {
        label: `${translate('header.upgrade_plan')}`,
        linkTo: PATH_PAGE.pricing,
      },
    ]
  ), [translate]);

  const { themeMode, onToggleMode } = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    tracking(TrackingType.HeaderLogoutItemClick);
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`${translate('header.logout_error')}`, { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    if (path === PATH_PAGE.pricing) {
      tracking(TrackingType.HeaderUpgradePlanItemClick);
    }
    handleClosePopover();
    navigate(path);
  };

  const handleClickThemeModeItem = () => {
    tracking(TrackingType.HeaderDarkModeItemClick);
    onToggleMode();
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {user?.expiry_date ? (
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {`${translate('header.expiration_date')}`}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {moment.unix(user?.expiry_date).format("MM/DD/YYYY")}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'row', my: 1.5, px: 2.5 }}>
            <Typography variant="body2" noWrap>
              {`${translate('header.your_credits')}`}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary', px: 1  }} noWrap>
              {user?.element_credits === 'unlimited' ? `${translate('header.unlimited')}` : user?.element_credits}
            </Typography>
          </Box>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}

          <MenuItem onClick={handleClickThemeModeItem}>
            <Stack spacing={2} direction="row" alignItems="center" display="inline-flex">
              {themeMode === 'dark' ? `${translate('header.light_mode')}` : `${translate('header.dark_mode')}`}

              <SvgColor
                sx={{
                  ml: '4px', 
                  mb: themeMode === 'dark' ? 0 : '2px', 
                  color: themeMode === 'dark' ? 'warning.main' : 'inherit',
                  width: '18px', 
                  height: '18px'}}
                src={`/assets/icons/setting/ic_${themeMode === 'light' ? 'moon' : 'sun'}.svg`}
              />
            </Stack>
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {`${translate('header.logout')}`}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
