import { useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DictationLayout from '../layouts/dictation';
import AdminLayout from '../layouts/admin';

// config
import { PATH_AFTER_LOGIN } from '../config-global';
import { useSettingsContext } from '../components/settings';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  SuccessRequestPage,
  ResetPasswordPage,
  // App: Dictation
  ChannelsPage,
  ElementsPage,
  DetailPage,
  // FAVORITES
  FavoritesPage,
  FavoritesElementPage,
  // ADMIN: App
  AdminChannelsPage,
  CreateChannelPage,
  EditChannelPage,
  AdminElementsPage,
  CreateElementPage,
  EditElementPage,
  ViewElementPage,
  // COMMON: Pages
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  DocsPage,
  AboutPage,
  Contact,
  PricingPage,
  ComingSoonPage,
  MaintenancePage,
  VerifyUserPage,
  PaymentSuccessPage,
  PaymentCanceledPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const { setThemeColor } = useSettingsContext();
    useEffect(() => {
      const isAdmin = location.pathname.includes('/admin');
      setThemeColor(isAdmin ? 'purple' : 'default')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify-user', element: <VerifyUserPage /> },
            { path: 'success-request', element: <SuccessRequestPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dictation
    {
      path: 'dictation',
      element: (
        <AuthGuard>
          <DictationLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dictation/channels" replace />, index: true },
        { path: 'channels', element: <ChannelsPage />},
        { path: 'channel/:channelId', element: <ElementsPage />},
        { path: 'channel/:channelId/element/:elementId', element: <DetailPage /> },
      ],
    },

    // FAVORITES
    {
      path: 'favorites',
      element: (
        <AuthGuard>
          <DictationLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/favorites/elements" replace />, index: true },
        { path: 'elements', element: <FavoritesPage />},
        { path: 'element/:elementId', element: <FavoritesElementPage />},
      ],
    },

    // Admin
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <AdminLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/admin/channels" replace />, index: true },
        { path: 'channels', element: <AdminChannelsPage />},
        { path: 'channel/create', element: <CreateChannelPage />},
        { path: 'channel/edit/:channelId', element: <EditChannelPage />},
        { path: 'channel/:channelId', element: <AdminElementsPage />},
        { path: 'channel/:channelId/element/create', element: <CreateElementPage />},
        { path: 'channel/:channelId/element/:elementId/edit', element: <EditElementPage />},
        { path: 'channel/:channelId/element/:elementId/view', element: <ViewElementPage />},
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <FaqsPage /> },
        { path: 'docs', element: <DocsPage /> },
      ],
    },
    {
      element: (<SimpleLayout />),
      children: [
        { path: 'pricing', element: <PricingPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: 'payment-success', element: <PaymentSuccessPage /> },
        { path: 'payment-canceled', element: <PaymentCanceledPage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
