// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const hk = {
  nav: {
    home: `主頁`,
    docs: `文檔`,
    login_now: `立即登錄`, 
    hi: `你好`,
    docs_description: `需要幫忙？\n 請查看如何使用Learmonade.`,
    documentation: `新手文檔`,
    dictation: `聽冩`,
    favorites: `收藏`,
  },
  header: {
    need_help: `需要幫助?`,
    logout: `退出登錄`,
    unlimited: `無限製`,
    upgrade_plan: `昇級計劃`,
    expiration_date: `有效期限`,
    your_credits: `你的額度:`,
    light_mode: `日間模式`,
    dark_mode: `夜間模式`,
    logout_error: `無法登錄!`,
  },
  error: {
    email_already_exist: `此電子郵件已被注冊.`,
    email_or_pwd_invalid: `郵箱或密碼不正確.`,
    error_401: `無效狀態，請重新加載頁麵或重新登錄!`,
    error_429: `請求太多，請稍後再試!`,
    error_something_wrong: `出錯了，請再試一次!`,
  },
  home: {
    page_title: `學習語言的起點`,
    title: `與我們一起 \n 開啟你的聽練學習`,
    description: `這是你語言學習的起點, 定製的學習素材幫助你更好更高效的學習語言。`,
    start_free: `免費開始`,
    help_title: `Learmonade如何 \n 幫助你?`,
    help_subtitle_1: `素材豐富`,
    help_description_1: `各種聽冩材料包括視頻, 音頻不間斷持續更新。`,
    help_subtitle_2: `趣味性`,
    help_description_2: `創新視頻模式助你遠離枯燥的學習方式。`,
    help_subtitle_3: `自動批改`,
    help_description_3: `結合學習報告的自動批改功能, 輕鬆複習重點句子。`,
    tutorial_subtitle: `指引文檔`,
    tutorial_title: `易於學習!`,
    tutorial_description: `閱讀新手教程文檔, 了解如何使用Learmonade!`,
    tutorial_btn: `立即啟程`,  
    report_subtitle: `專業工具`,  
    report_title: `報告預覽`, 
    theme_mode_subtitle: `易於切換的主題模式`, 
    theme_mode_title: `夜間模式`, 
    theme_mode_description: `夜間模式幫助你在夜間學習時減輕眼睛負擔`, 
    materials_subtitle: `簡潔 & 清晰`, 
    materials_title: `強大的資源庫支持, 持續更新的學習素材`, 
    bottom_title: `立即開啟 \n Learmonade學習之旅`, 
    start_now: `立即開始`, 
  },
  login: {
    welcome: `Hi, 歡迎回來`,
    page_title: `登錄`, 
    title: `登錄Learmonade`,
    new_user: `新用戶?`, 
    create_account: `創建賬號`,
    email: `郵箱地址`, 
    password: `密碼`, 
    forget_password: `忘記密碼?`, 
    login: `登錄`, 
    or: `或`, 
    google_login: `Google賬號登錄`, 
    require_email: `請填冩郵件地址`,
    require_valid_email: `請輸入有效的郵箱地址`,
    require_password: `請填冩密碼`,
  },
  register: {
    welcome: `Learmonade | 開啟高效學習之旅`,
    title: `開啟你的學習旅程.`,
    page_title: `注冊`,
    already_has_account: `已有賬號?`,
    sign_in: `登錄`,
    first_name: `姓氏`,
    last_name: `名字`,
    email_address: `郵箱地址`,
    password: `密碼`,
    password_confirm: `確認密碼`,
    create_account: `創建賬號`,
    agreement: `注冊後，我同意`,
    service_terms: `服務條款`,
    privacy_policy: `隱私政策`,
    and: `和`,
    require_first_name: `請填冩姓氏`,
    require_last_name: `請填冩名字`,
    require_email: `請填冩郵箱地址`,
    require_valid_email: `請填冩有效的郵箱地址`,
    require_password: `請填冩密碼`,
    require_password_confirm: `請確認兩次密碼相同`,
  },
  verify_code: {
    page_title: `用戶驗証`,
    title: `請查收您的電子郵箱!`,
    sending: `郵件髮送中...`,
    description: `我們已將郵箱認証鏈接髮送到您的郵箱，請檢查並完成您的賬戶驗証。`,
    cannot_receive: `無法收到郵件? 請檢查您的`,
    spam_email: `垃圾郵箱`,
    and: `或`,
    resend: `點擊重髮`,
    return: `返回登錄`,
    errors: `請在瀏覽器url地址末尾加上''?email=xxx@xx''(你的郵箱地址)作爲瀏覽器url的後綴並刷新頁麵。`,
    success: `郵件髮送成功!`
  },
  verify_user: {
    page_title: `用戶驗証`,
    success_title: `恭喜!`,
    success_description: `您的帳號已注冊成功!請登錄Learmonade，開啟學習。`,
    verifying: `驗証中...`,
    error_title: `對不起, 出錯了, 請再試一次!`,
    error_description: `我們暫時無法驗証您的賬號。請刷新網站或直接登錄您的賬號以重新接收驗証郵件。`,
    login: `返回登錄`,
  },
  new_password: {
    page_title: `設置新密碼`,
    title: `確認成功!`,
    description: `請在下麵輸入框中設置您的新密碼。`,
    password: `密碼`,
    confirm_password: `再次輸入密碼`,
    update_password: `更新密碼`,
    return_login: `返回登錄`,
    require_eight_password_chars: `請輸入至少8個字符的密碼`,
    require_password: `請填冩密碼`,
    require_password_confirm: `請再次填冩密碼`,
    require_password_match: `兩次密碼輸入不符`,
    toast_change_success: `密碼更改成功!`,
  },
  reset_password: {
    page_title: `重置密碼`,
    title: `忘記密碼?`,
    description: `請輸入您帳戶的電子郵件地址，我們將通過電子郵件向您髮送重置密碼的鏈接。`,
    email_address: `郵箱地址`,
    send_request: `確認髮送`,
    return_sign_in: `返回登錄`,
    require_email: `請填冩郵箱地址`,
    require_valid_email: `請填冩有效的郵箱地址`,
  },
  success_request: {
    page_title: `髮送成功`,
    title: `郵件髮送成功!`,
    description_1: `我們已經向您的郵箱髮送了確認郵件。`,
    description_2: `請檢查收件箱中的確認鏈接以重置密碼。`,
    do_not_receive: `無法收到郵件?`,
    resend_email: `重新髮送`,
    return_sign_in: `返回登錄`,
  },
  pricing: {
    page_title: `價格`,
    title_1: `根據您的學習需求`,
    title_2: `選擇計劃`,
    description: `選擇您的計劃，開啟輕鬆的語言學習之旅`,
    popular: `熱門`,
    basic: `基礎`,
    starter: `進階`,
    premium: `高級`,
    one_month_plan: `1個月套餐`,
    three_months_plan: `3個月套餐`,
    six_months_plan: `6個月套餐`,
    unlimited_credits: `無限製的素材學習額度`,
    latest_materials: `支持最新的學習素材(持續更新)`,
    auto_checking: `自動聽冩校對`,
    dictation_report: `支持聽冩模式`,
    one_month_money_saving: `節省金錢`,
    three_months_money_saving: `節省金錢 (多至 $2)`,
    six_months_money_saving: `節省金錢 (多至 $5)`,
    one_month_fixed_price: `以固定價格享受最新功能`,
    three_months_fixed_price: `以固定價格享受最新功能 (有效期3個月)`,
    six_months_fixed_price: `以固定價格享受最新功能 (有效期6個月)`,
    choose_basic: `選擇基礎版`,
    choose_starter: `選擇進階版`,
    choose_premium: `選擇高級版`,
  },
  faqs: {
    page_title: `常見問題`,
    title: `常見問題`,
    question_1: `如何聯繫Learmonade以獲得進一步的支持?`,
    answer_1: `聯繫郵箱地址:`,
    question_2: `有Learmonade的新手文檔嗎?`,
    answer_2_please: `請`,
    answer_2_click: `點擊`,
    answer_2_read_details: `閱讀詳情。`,
  },
  docs: {
    page_title_prefix: `新手文檔:`,
    title: `如何使用Learmonade?`,
    nav_title: `Learmonade | 新手文檔`,
    nav_home: `主頁`,
    nav_docs: `文檔`,
    steps_title: `如何使用Learmonade?`,
    steps_caption: `Learmonade | 我們一起開始語言學習吧~`,
    steps_description: `本指南將介紹用如何用Learmonade進行高效語言學習以及基本介紹學習流程。對於初學者來説，這可以幫助您快速開始學習。讓我們開始吧!`,
    step_1_title: `1. 基礎學習流程介紹`,
    step_1_description: `在這個視頻中，我們將介紹Learmonade的基本學習流程, 這種學習方法可以幫助初學者利用Learmonade高效學習英語以及提昇打字速度。`,
    step_2_title: `2. 藍牙設備設置`,
    step_2_description_1: `如果使用藍牙設備如藍牙耳機進行學習，請確保您已在設置中打開 `,
    step_2_description_2: `Bluetooth Device`,
    step_2_description_3: `(藍牙設備)選項.`,
    step_3_title: `3. 鍵盤快捷鍵`,
    step_3_description: `Learmonade學習界麵基礎鍵盤快捷鍵如下: `,
    step_4_title: `4. 如何關閉視頻字幕? (針對YouTube觀看的用戶)`,
    step_4_description: "第1步，在設置對話框中打開`Showing Video Controls` (顯示視頻控件)選項。第二步，點擊關閉YouTube下方字幕設置，如下圖2所示。步驟3，重新加載頁麵。",
    step_5_title: `5. 聯繫我們`,
    step_5_description: `如有任何問題，歡迎隨時聯繫我們:`,
  },
  dictation: {
    first_visit_title: `新手訪問指南`,
    first_visit_description: `Learmonade快速學習指南:`,
    first_visit_list_1_1: `每月3個素材額度`,
    first_visit_list_1_2: `給予免費用戶，每個素材需要花費一個額度，額度會在`,
    first_visit_list_1_3: `每月1號重置`,
    first_visit_list_1_4: `，可以通過點擊右上角用戶頭像來查看本月剩餘額度。`,
    first_visit_list_2_1: `付費用戶`,
    first_visit_list_2_2: `擁有無限額度，可以訪問平颱所有的學習素材。`,
    first_visit_list_3_1: `如果使用藍牙耳機學習，在學習前請確保已打開`,
    first_visit_list_3_2: `Bluetooth Device`,
    first_visit_list_3_3: `(藍牙設備)設置。`,
    first_visit_list_4_1: `請閱讀`,
    first_visit_list_4_2: `文檔`,
    first_visit_list_4_3: `以了解Learmonade基本學習流程和更多細節，強烈建議您在學習之前閱讀文檔。`,
    first_visit_list_5: `基礎快捷鍵: Tab: 重聽當前句, ↑鍵: 播放上一句, ↓鍵: 播放下一句`,
    
    settings_title: `聽冩設置`,
    settings_showing_video: `顯示視頻`,
    settings_showing_video_controls: `顯示視頻控件`,
    settings_bluetooth_device: `藍牙設備`,
    settings_reset_learning: `重置素材`,
    settings_reset: `重置`,

    typing_area_placeholder: `輸入你的答案`,
    typing_area_loading: `正在加載你的學習進度...`,

    no_credits_title: `額度不足`,
    no_credits_content_1: `您的月度素材額度已用完，請等待每月1號重置或昇級您的計劃以獲得`,
    no_credits_content_2: `無限額度`,
    no_credits_later: `稍後`,
    no_credits_upgrade_plan: `昇級計劃`,

    reset_learning_title: `重置學習記錄`,
    reset_learning_content: `你還未沒有當前素材的學習，仍然想重置學習記錄嗎?`,
    reset_learning_no: `忽略`,
    reset_learning_yes: `重置`,

    auto_play_confirm_title: `自動播放模式`,
    auto_play_confirm_content: `在自動播放模式下，句子會自動按順序播放，直到該素材結束。您要開啟自動播放模式嗎?`,
    auto_play_confirm_yes: `是的`,
    auto_play_confirm_no: `不需要`,

    complete_learning_title: `恭喜!`,
    complete_learning_content: "您已完成本素材的學習! 如果您想重新學習此內容，請前往「設置」選擇重置學習記錄。",
    complete_learning_ok: `好的`,

    typing_mode_click_or_press: `點擊或按Tab鍵激活...`,
    typing_mode_metrics: `指標:`,
    typing_mode_speed: `速度:`,
    typing_mode_accuracy: `準確率:`,
    typing_mode_typing_settings: `打字設置:`,
    typing_mode_ignore_case: `忽略大小冩:`,
    typing_mode_ignore_punctuation: `忽略標點:`,

    report_mode_title: `學習報告`,
    report_mode_total_score: `總分`,
    report_mode_excellent: `優秀`,
    report_mode_good: `不錯`,
    report_mode_poor: `有待提昇`,
    report_mode_under_60: `低於60`,
    report_mode_compare: `對比:`,
    report_mode_your_answer: `你的回答:`,
    report_mode_score: `得分:`,
    report_mode_accuracy: `正確率:`,

    reading_mode: `閱讀模式`,
    typing_mode: `打字模式`,
    dictation_mode: `聽冩模式`,
    report_mode: `報告模式`,
    close: `關閉`,
  },
  admin: {
    settings_title: `管理設置`,
    settings_hidden_channels: `隱藏未上線的頻道:`,
    settings_hidden_elements: `隱藏未上線的素材:`,
    close: `關閉`, 
  },
  page_error: {
    title: `出了點問題!`,
    description: `遇到一個錯誤，請嚐試刷新頁麵。`,
    reload: `刷新頁麵`,
  },
  page_404: {
    title: `對不起，頁麵不存在!`,
    description: `我們找不到您要的頁麵，請檢查頁麵地址是否拼冩正確。`,
    go_to_home: `返回主頁`,
  },
  payment_success: {
    page_title: `支付成功`,
    title: `您的付款交易已成功`,
    description: `請點擊開啟您的學習!`,
    go_to_explore: `開啟學習`,
  },
  payment_canceled: {
    page_title: `付款失敗`,
    title: `您的付款交易被取消`,
    description: `如需要幫助，請郵件聯繫我們!`,
    go_to_home: `返回主頁`,
  }
};

export default hk;
