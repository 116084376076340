import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps } from '@mui/material';
// hooks
import useImagePrefix from '../../hooks/useImagePrefix';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  isOutsidePage?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ isOutsidePage, disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // const imagePrefix = useImagePrefix('/logo/logo_single.svg');

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src={`${imagePrefix}/logo_single.svg`}
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg viewBox="0 0 348 264" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_203_37)">
            <g filter="url(#filter0_d_203_37)">
            <path fillRule="evenodd" clipRule="evenodd" d="M261.282 153.904C215.012 234.86 167.182 311.132 112.237 199.248L174.244 98.7496C195.521 138.984 219.925 169.224 270.972 136.908C269.02 140.362 266.844 144.17 264.552 148.18L264.548 148.187L264.544 148.193L264.543 148.195C263.477 150.06 262.387 151.968 261.282 153.904Z" fill="url(#paint0_linear_203_37)"/>
            <path d="M113.091 200.848L174.417 98.4614L77.1726 137.212C89.6726 162.212 105.74 186.778 113.091 200.848Z" fill="url(#paint1_linear_203_37)"/>
            <path d="M77.4729 138.25L112.237 199.248C104.886 185.178 90.0926 164.028 77.3199 138.256L77.4729 138.25Z" fill="url(#paint2_linear_203_37)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M174.053 98.7999L176.971 93.5019L179.944 88.1388L182.79 83.0438L185.064 79.0008L187.25 75.1439L189.756 70.7609L191.356 67.9869L193.65 64.0478L194.749 62.1789L196.165 59.7909L197.19 58.0778L198.507 55.8979L199.457 54.3399L200.971 51.8928L201.837 50.5129L202.67 49.2009L203.472 47.9549L204.242 46.7749L204.981 45.6638L205.916 44.2839L206.58 43.3278L207.005 42.7279L207.415 42.1558L208.005 41.3559L208.381 40.8588L208.918 40.1688L209.089 39.9549C219.849 26.5849 231.585 16.4618 246.019 10.6208C276.365 -3.64116 314.089 -4.30815 343.221 13.3248L270.874 138.007L268.074 139.727C218.817 169.053 194.993 138.61 174.053 98.7999Z" fill="url(#paint3_linear_203_37)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M174.141 99.0494L171.223 93.7514L168.25 88.3884L165.404 83.2934L163.13 79.2504L160.944 75.3934L158.438 71.0104L156.838 68.2364L154.544 64.2974L153.445 62.4284L152.029 60.0404L151.004 58.3274L149.687 56.1474L148.737 54.5894L147.223 52.1424L146.357 50.7624L145.524 49.4504L144.722 48.2044L143.952 47.0244L143.213 45.9134L142.278 44.5334L141.614 43.5774L141.189 42.9774L140.779 42.4054L140.189 41.6054L139.813 41.1084L139.276 40.4184L139.105 40.2044C128.345 26.8344 116.609 16.7114 102.175 10.8704C71.8289 -3.39159 34.1049 -4.05858 4.9729 13.5744L77.3199 138.256C122.92 166.268 152.312 140.55 174.141 99.0494Z" fill={PRIMARY_LIGHT}/>
            </g>
          </g>
          <defs>
            <filter id="filter0_d_203_37" x="0.9729" y="-7.62939e-06" width="346.248" height="263.488" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_203_37"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_203_37" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear_203_37" x1="23317.4" y1="5.8172e-07" x2="23317.4" y2="25959.8" gradientUnits="userSpaceOnUse">
              <stop stopColor={PRIMARY_LIGHT}/>
              <stop offset="1" stopColor={PRIMARY_MAIN}/>
            </linearGradient>
            <linearGradient id="paint1_linear_203_37" x1="23317.4" y1="-2.35337e-05" x2="23317.4" y2="25959.8" gradientUnits="userSpaceOnUse">
              <stop stopColor={PRIMARY_LIGHT}/>
              <stop offset="1" stopColor={PRIMARY_MAIN}/>
            </linearGradient>
            <linearGradient id="paint2_linear_203_37" x1="23317.4" y1="-1.54894e-05" x2="23317.4" y2="25959.8" gradientUnits="userSpaceOnUse">
              <stop stopColor={PRIMARY_LIGHT}/>
              <stop offset="1" stopColor={PRIMARY_MAIN}/>
            </linearGradient>
            <linearGradient id="paint3_linear_203_37" x1="33829.8" y1="2541.09" x2="20762.5" y2="16399.9" gradientUnits="userSpaceOnUse">
              <stop stopColor={PRIMARY_DARK}/>
              <stop offset="1" stopColor={PRIMARY_MAIN}/>
            </linearGradient>
            <clipPath id="clip0_203_37">
              <rect width="348" height="264" fill="white"/>
            </clipPath>
          </defs>
        </svg>

      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link 
        onClick={() => {if (isOutsidePage) window.location.href = '/'; }} 
        component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
