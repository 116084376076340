import localStorageAvailable from './localStorageAvailable';

export enum StorageType {
    playbackRate = 'playbackRate',
    learningMode = 'learningMode',
    isBluetoothDevice = 'isBluetoothDevice',
    isIgnoreCase = 'isIgnoreCase',
    isIgnorePunctuation = 'isIgnorePunctuation',
    isShowTypingSettings = 'isShowTypingSettings',
    isShowingVideo = 'isShowingVideo',
    isAutoPlayingMode = 'isAutoPlayingMode',
    isFirstVisit = 'isFirstVisit',
    userId = 'userId',
    from = 'from',
} 

export const getStorage = (storageType: StorageType) => {
    if (localStorageAvailable()) {
        const value = localStorage.getItem(storageType);
        return value && JSON.parse(value);
    }
    return undefined;
}

export const setStorage = (storageType: StorageType, value: any) => {
    if (localStorageAvailable()) {
        localStorage.setItem(storageType, JSON.stringify(value));
    }
};
