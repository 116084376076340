// ----------------------------------------------------------------------

export const fullAddress = [
  '19034 Verna Unions Apt. 164 - Honolulu, RI / 87535',
  '1147 Rohan Drive Suite 819 - Burlington, VT / 82021',
  '18605 Thompson Circle Apt. 086 - Idaho Falls, WV / 50337',
];

export const country = [
  'Kenya',
  'Madagascar',
  'Netherlands Antilles',
];
