// locales
import i18n from '../locales/i18n';

const getErrorsMap = (message:string) => {
    switch (message) {
        case 'A user with that username already exists.':
            return `${i18n.t('error.email_already_exist')}`;
        case 'Unable to log in with provided credentials.':
            return `${i18n.t('error.email_or_pwd_invalid')}`;
        default:
            return message;
    }
}

export const errorsParse = (error:any) => {
    const errorMsg = error?.data || error;
    if (typeof errorMsg === 'string') {
        return errorMsg
    }
    if (Object.keys(errorMsg)[0]) {
        const message = errorMsg[Object.keys(errorMsg)[0]][0];
        return getErrorsMap(message);
    }
    return errorMsg.detail || error.toString();
};