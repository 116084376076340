import { Translation } from 'react-i18next';
// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// config
import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------
const navConfig = [
  {
    id: 'home',
    title: <Translation>{(translate) => translate('nav.home')}</Translation>,
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  // TODO: Restore in the future!
  // { 
  //   id: 'pricing',
  //   title: 'Pricing',
  //   icon: <Iconify icon="solar:tag-price-bold" />,
  //   path: PATH_PAGE.pricing,
  // },
  {
    id: "docs",
    title: <Translation>{(translate) => translate('nav.docs')}</Translation>,
    icon: <Iconify icon="eva:book-open-fill" />,
    path: PATH_PAGE.docsPage,
  },
  {
    id: "discord",
    title: "Discord",
    icon: <Iconify icon="ic:baseline-discord" />,
    path: "https://discord.gg/bNnbeEkKpU",
  },
];

export default navConfig;
