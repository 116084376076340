import { useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../../hooks/useActiveLink';
// components
import { NavSectionVertical } from '../../../../components/nav-section';
// hooks
import useImagePrefix from '../../../../hooks/useImagePrefix';
// utils
import { tracking, TrackingType } from '../../../../utils/trackingEvent';
//
import { NavItemProps } from '../types';
import NavItem from './NavItem';

// ----------------------------------------------------------------------

type NavListProps = {
  item: NavItemProps;
};

export default function NavList({ item }: NavListProps) {
  const { pathname } = useLocation();

  const { path, children } = item;

  const { isExternalLink } = useActiveLink(path);

  const [open, setOpen] = useState(false);

  const imagePrefix = useImagePrefix('/assets/illustrations');

  return (
    <>
      <NavItem
        item={item}
        open={open}
        onClick={() => {
          if (item.id === 'docs') {
            tracking(TrackingType.HomeNavDocClick);
          }
          if (item.id === 'discord') {
            tracking(TrackingType.HomeNavDiscordClick);
          }
          setOpen(!open)
        }}
        active={pathname === path}
        isExternalLink={isExternalLink}
      />

      {!!children && (
        <Collapse in={open} unmountOnExit>
          <NavSectionVertical
            data={children}
            sx={{
              '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                height: 160,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                bgcolor: 'background.neutral',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${imagePrefix}/illustration_dashboard.webp)`,
                '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
              },
            }}
          />
        </Collapse>
      )}
    </>
  );
}
