import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Typography, Stack, Container } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { SeverErrorIllustration } from '../assets/illustrations';
// @mui
// hooks
import useOffSetTop from '../hooks/useOffSetTop';
// config
import { HEADER } from '../config-global';
// locales
import { useLocales } from '../locales';
//
import Header from '../layouts/compact/Header';

// ----------------------------------------------------------------------

export default function ErrorPage() {
  const { translate } = useLocales();
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      <Header isOutsidePage isOffset={isOffset} />

      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Helmet>
            <title> Page Error | Learmonade</title>
          </Helmet>

          <MotionContainer>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                {`${translate('page_error.title')}`}
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                {`${translate('page_error.description')}`}
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>

            <Button onClick={() => window.location.reload()} size="large" variant="contained">
              {`${translate('page_error.reload')}`}
            </Button>
          </MotionContainer>
        </Stack>
      </Container>
    </>
  );
}
