import { defaultLang, LangType } from '../locales/config-lang';
import localStorageAvailable from './localStorageAvailable';

export function getLang() : LangType {
  let lng = defaultLang.value;
  const storageAvailable = localStorageAvailable();

  if (storageAvailable) {
    lng = localStorage.getItem('i18nextLng') as LangType || defaultLang.value;
  }

  return lng;
}